const menuItems = document.querySelectorAll('.menu-item');
const burger = document.querySelector('#navbar-burger-id');
const menu = document.querySelector('#menu');
const projects = document.querySelector('#projects');

[].forEach.call(menuItems, function(e) {
    e.addEventListener('click', function(){
        burger.classList.remove('is-active');
        menu.classList.add('is-hidden');
    }, false)
});

burger.addEventListener('click', function(){
    burger.classList.toggle('is-active');
    const isHidden = menu.classList.toggle('is-hidden');
    if (projects)
        projects.style.zIndex = (isHidden - 1).toString();
}, false)